.night .login-page {
  background: #1f1f1f;
}

.night .login-title {
  color: #FFFFFFD9;
}



.login-header {
  width: 100%;
  left: 0px;
  padding: 1.5625rem 0px;
  line-height: 0;
  user-select: none;
  position: absolute;
  z-index: 122;
  pointer-events: none;
}

.login-header-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.brand {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.brand a {
  font-size: 20px;
  text-decoration: none;
  color: black;
  font-weight: 500;
}

.language {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.login-page {
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative
}

.login-section {
  overflow: visible;
  position: static;
  padding: 10.625rem 0px 2.5rem !important;
}

.login-container {
  padding-left: 25px;
  padding-right: 25px;
  max-width: calc(1270px);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.login-panel {
  width: 32.5rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.login-title {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: -0.38px;
  margin-bottom: 3.125rem;
  text-align: center;
}

.reg-link {
  font-size: 1.3125rem;
  line-height: 1.33;
  pointer-events: all;
  z-index: 120;
  text-align: center;
  margin: -1.875rem auto 2.5rem;
  max-width: 24.375rem;
  color: inherit;
}

.learn-more {
  color: rgb(41, 41, 41);
  font-weight: 500;
  position: relative;
  cursor: pointer;
  display: inline-block;
  background: transparent;
  border: 0px;
  padding: 0px;
  appearance: none;
  /*border-bottom: solid #1890ff 3px;*/
}

.learn-more:hover {
  color: black;
}

.learn-more::after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  bottom: -1px;
  border-bottom: 3px solid #1890ff;
  transition: width 0.3s ease 0s;
}

.learn-more:hover::after {
  width: 0;
}

.login-form {
  letter-spacing: 0px;
  font-size: 1.3125rem;
  line-height: 2.25rem;
  color: rgb(0, 0, 0);
}

#eth_login_email, #eth_login_phone, 
#eth_login_password, #eth_login_code, #eth_login_confirm {
  font-family: inherit;
  font-size: 23px;
  color: rgb(0, 0, 0);
  margin: 7px;
}

.form-bottom {
  padding-top: 20px;
  display: flex;
  align-items: center;
}

#login-form-button {
  height: 50px;
  padding: 0.4px 30px;
  font-size: 20px;
  border-radius: 2px;
}

.login-button {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.forget-pwd {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
  font-size: 20px;
  text-align: right!important;
  margin-bottom: 24px;
  padding-right: 25px;
}

#login-form-button-con {
  margin-bottom: 0 !important;
}

.login-footer {
  padding: 2.5rem 1.25rem 5rem;
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.footer-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.social-media-list {
  list-style: none;
  margin: 0px 0px 1.75rem;
  padding: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  direction: ltr;
}

.social-media-list li{
  text-align: center;
  margin: 0px 0.5625rem;
}

/*
.social-media-list li a{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 2.8125rem;
  height: 2.8125rem;
  color: rgb(0, 0, 0);
  border-radius: 100px;
  background-color: #1890ff;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
}
*/

.contact-us {
  text-align: center;
  font-weight: 500;
  margin-bottom: 0.9375rem;
  font-size: 20px;
}

.service {
  font-size: 1.0625rem;
  color: rgb(154, 154, 154);
  letter-spacing: 0px;
  text-align: center;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.9375rem;
}

.service a, .author a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease 0s;
  outline: none !important;
}

.author {
  font-size: 1.0625rem;
  color: rgb(154, 154, 154);
  letter-spacing: 0px;
  text-align: center;
  line-height: 1.5rem;
}

.code-box {
  display: flex;
}

.code-button {
  width: 35%;
  text-align: right;
}

#code-button {
  height: 60px;
  margin-left: 15px;
  font-size: 23px;
  padding-left: 20px;
  padding-right: 20px;
}

#to-reg {
  margin-left: 30px;
  font-size: 20px;
  color: black;
}

#to-reg a {
  color: rgb(65, 63, 63);
}

#to-reg:hover {
  color: #1890ff;
}

@media screen and (max-width: 767px) {
  .login-title {
    font-size: 30px;
  }
  .form-bottom {
    display: block;
  }
  .login-button {
    width: 100%;
    margin: 0 auto;
    flex: none;
    max-width: none;
  }
  #login-form-button {
    width: 100%;
  }
  .to-reg-con {
    padding: 10px 0;
    text-align: center;
  }
  #to-reg {
    margin-left: 0;
  }
  .forget-pwd {
    flex: none;
    max-width: none;
    text-align: center !important;
    padding-right: 0;
    margin-bottom: 20px;
  }
  #code-button {
    font-size: 15px;
    width: 99px;
    word-break: break-word;
    padding: 1px;
  }
}