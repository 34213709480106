.calculator-home .ant-modal-footer {
  display: none !important;
}

.calculator-home .ant-modal-title {
  text-align: center;
}


.calculator-input-con {
  padding: 0 3px;
}

.calculator-input-title {
  font-size: 16px;
  color: #383838;
  margin: 0px 0 10px;
}

.comment-box {
  margin: 0;
  color: #07a5ec;
  font-size: 13px;
}

.night .calc-swap {
  color: #FFFFFFD9;
  font-size: 20px;
}

.calc-swap {
  color: black;
  font-size: 20px;
}

.select-img {
  height: 16px;
  margin-right: 6px;
  margin-bottom: 2px;
}

.select-img-con {
  display: inline-block;
  width: 26px;
}

.addition-line {
  padding: 15px 0 8px;
  width: 65%;
}

.calculator-hanging-box {
  position: absolute;
  bottom: -27px;
  font-size: 13px;
  right: 0;
  margin-right: 12px;
}

.night .calculator-hanging-box {
  color: #FFFFFFa6 !important;
}

.night .calculator-input-con {
  padding: 0 3px;
}

.night .calculator-input-title {
  font-size: 16px;
  color: #FFFFFFA6;
  margin: 0px 0 10px;
}

.night .ant-select-selector {
  background-color: #2c2c2c !important;
  color: #FFFFFFD9 !important;
  border-color: #FFFFFFD9 !important;
}

.night .ant-input-affix-wrapper {
  background-color: #2c2c2c !important;
  color: #FFFFFFD9 !important;
  border-color: #FFFFFFD9 !important;
}

.night .ant-input {
  background-color: #2c2c2c !important;
  color: #FFFFFFD9 !important;
  border-color: #FFFFFFD9 !important;
}

.night .ant-select-arrow {
  color: #FFFFFFD9 !important;
}

.night .ant-select-dropdown {
  background-color: #2c2c2c !important;
  color: #FFFFFFD9 !important;
  border-color: #FFFFFFD9 !important;
}

.night .ant-select-item-option-content {
  color: #FFFFFFD9 !important;
}

.night .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #505050 !important;
}

.night .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #505050 !important;
}

.night .comment-box {
  color: #03a9f4;
  font-size: 13px;
}

.night .announce-modal .ant-modal-confirm-body .ant-modal-confirm-content {
  color: #FFFFFFD9 !important;
}

.night .announce-modal .ant-modal-confirm-body .ant-modal-confirm-title {
  color: #FFFFFFA6 !important;
}

.night .addition-line {
  color: #FFFFFFA6;
}
