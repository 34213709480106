.search-page {
  background-color: var(--background-color);
}

.search-container {
  max-width: 1200px;
  min-width: 700px;
  width: 70vw;
  margin: 50px auto 50px;
}

.search-page-search-bar {
  width: 500px;
  min-width: 600px;
  max-width: 1100px;
  height: 2.6rem;
  font-size: 1rem;
}

.search-page-search-bar input {
  width: 100% !important;
  height: 2.6rem !important;
  padding: 0.1rem 0.5rem !important;
  color: #4a4949 !important;
  border: solid 2px var(--search-border-color) !important;
  border-radius: 0.5rem 0 0 0.5rem !important;
}

.search-page-search-bar input:focus {
  border: solid 3px var(--color6) ;
  outline: none;
}

.spin-box {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coin-type-box {
  margin: 0px auto 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.coin-type-box img {
  height: 30px;
  cursor: pointer;
}

.coin-type-box-title {
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.divider-line {
  margin-top: 0;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  background: #e6e6e6;
  height: 1px;
  width: 94%;
}

.container {
  padding: 4rem 0 2rem;
  width: max-content;
}

.account-info {
  height: 3rem;
  background-color: white;
  color: #5d5d5d;
  box-shadow: 0 0 10px 0 rgb(162 177 192 / 20%);
  margin: 0 0.5rem 0;
  font-size: 1rem;
  text-align: center;
  line-height: 3rem;
  border-radius: 1rem;
}

.search-sec {
  margin: 0rem auto 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coin-logo-con {
  height: 2.5rem;
  width: 3rem;
}

.coin-logo-con img {
  height: 100%;
  width: 100%;
}

.coin-logo-name {
  display: flex;
  align-items: center;
  width: 30%;
}

.coin-name {
  margin: 0 0.5rem;
  font-size: 2rem;
}

.main-panel-search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar {
  width: 28rem;
  height: 2.6rem;
  font-size: 1rem;
}

.search-bar input {
  width: 100%;
  height: 100%;
  padding: 0.1rem 0.5rem;
  color: #4a4949;
  border: solid 2px var(--search-border-color);
  border-radius: 0.5rem 0 0 0.5rem;
}


.searchPage .panel-table-thead th {
  padding: 1rem;
  font-weight: 400;
}

.search-btn {
  font-size: 1.2rem;
  color: white;
  text-align: center;
  line-height: 2.6rem;
  width: 6rem;
  height: 2.6rem;
  background-color: var(--search-btn-color);
  border-radius: 0 0.5rem 0.5rem 0;
  transition: transform 0.3s ease 0s, box-shadow 0.3s ease 0s;
}

.search-btn:hover {
  cursor: pointer;
  box-shadow: rgb(125 132 153 / 4%) 0px 2px 4px 0px, rgb(125 132 153 / 20%) 0px 4px 18px 0px;
  transform: scale(1.03);
}

.data-sec {
  margin: 1.5rem 0 1.5rem;
}

.data-block {
  border-radius: 1rem;
  height: 10rem;
  margin: 0.5rem;
  background-color: white;
  box-shadow: 0 0 10px 0 rgb(162 177 192 / 20%);
}

.block-title {
  text-align: center;
  font-size: 1.3rem;
  padding: 1.4rem;
  color: #33364a;
}

.lower-title {
  text-align: center;
  font-size: 1rem;
  color: #4c4c4c;
}

.lower-figure {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 0.2rem;
  color: #000;
  font-weight: 500;
}

.transfer-sec {
  margin: 1rem 0 2rem;
}

.transfer-block {
  border-radius: 15px;
  height: 50px;
  margin: 0.5rem;
  background-color: white;
  box-shadow: 0 0 10px 0 rgb(162 177 192 / 20%);
}

.transfer-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 15px;
  color: #33364a;
}

.transfer-range {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #33364a;
}


.transfer-range-minus-btn-con {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.transfer-range-add-btn-con {
  width: 30px;
  height: 30px;
  margin-left: 6px;
}

.transfer-range-btn {
  text-align: center;
  background: #1890ff;
  color: white;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  line-height: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.4s ease;
}

.transfer-range-btn:hover {
  cursor: pointer;
  background: #1686ec;
}

.transfer-range-figure {
  font-size: 16px;
  font-weight: 500;
  user-select: none;
}

.graph-sec {
  margin: 0 0 1.5rem;
}

.graph-panel {
  border-radius: 1rem;
  height: 26rem;
  margin: 0.5rem;
  background-color: white;
  box-shadow: 0 0 10px 0 rgb(162 177 192 / 20%);
}

.graph-title {
  user-select: none;
  font-size: 1.2rem;
  text-align: center;
  line-height: 4rem;
  height: 4rem;
  color: #33364a;
}

.etc-btns-sec {
  margin: 0 0 0.5rem;
}

.etc-btns-panel {
  border-radius: 1rem;
  height: 4rem;
  background-color: var(--color6);
  box-shadow: 0 0 10px 0 rgb(162 177 192 / 20%);
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: white;
  text-align: center;
}

.etc-btn {
  margin: 0 1rem;
  line-height: 4rem;
  border-bottom: solid 3px white;
  cursor: pointer;
  color: #f9f9f9;
}

.table-panel {
  padding: 0.5rem;
}

.main-panel-table {
  margin: 1rem 0 1rem;
  box-shadow: 0 0 10px 0 rgb(162 177 192 / 20%);
}

.panel-table-thead {
  background-color: var(--color6);
  font-size: 1rem;
  text-align: center;
  color: white;
}

.panel-table-thead th {
  padding: 1rem;
  font-weight: 400;
}

.action {
  font-size: 1rem;
  line-height: 1.4rem;
  color: var(--color6);
}

.addr {
  font-size: 0.9rem;
  color: #434343;
  border-top: solid 1px #e6e6e6;
  border-bottom: solid 1px #e6e6e6;
}

.table-sec .ant-table-thead > tr > th {
  border-bottom: 1px solid #f0f0f0 !important;
  transition: background 0.3s ease !important;
  background-color: white !important;
  font-size: 14px !important;
  padding: 0.11rem !important;
  font-weight: 400 !important;
  text-align: center !important;
  color: #5d5d5d !important;
}

.coin-table-sec .ant-table-thead > tr > th {
  border-bottom: 1px solid #f0f0f0 !important;
  transition: background 0.3s ease !important;
  background-color: white !important;
  font-size: 14px !important;
  padding: 12px 0px 8px !important;
  font-weight: 400 !important;
  text-align: center !important;
  color: #4b4b4b !important;
}

.table-sec .ant-table-tbody > tr {
  height: 3rem;
  font-size: 14px;;
  text-align: center !important;
  color: #4e4e4e;
  border-bottom: solid 1px #eee;
}

.coin-table-sec .ant-table-tbody > tr {
  height: 3rem;
  font-size: 14px;
  text-align: center !important;
  color: #3c3c3c;
  border-bottom: solid 1px #eee;
}


table tr:first-child th:first-child{
  border-top-left-radius: 10px;     /* 设置table左上圆角 */
}
table tr:first-child th:last-child{
  border-top-right-radius: 10px;     /* 设置table右上圆角 */
}
tbody tr:last-of-type td:first-child{
  border-bottom-left-radius: 10px !important; /* 设置table左下圆角 */
}
tbody tr:last-child td:last-child{
  border-bottom-right-radius: 10px !important;/* 设置table右下圆角 */
}

.main-panel-table {
  border-radius: 1rem !important;
}

.ant-table tr:first-child th:first-child{
  border-top-left-radius: 10px;     /* 设置table左上圆角 */
}
.ant-table tr:first-child th:last-child{
  border-top-right-radius: 10px;     /* 设置table右上圆角 */
}
.ant-table tr:last-of-type td:first-child{
  border-bottom-left-radius: 10px !important; /* 设置table左下圆角 */
}
.ant-table tr:last-child td:last-child{
  border-bottom-right-radius: 10px !important;/* 设置table右下圆角 */
}

.ant-table-thead tr th:first-child{
  border-top-left-radius: 15px !important;
}
.ant-table-thead tr th:last-child{
  border-top-right-radius: 15px !important;
}
.ant-table-tbody tr th:last-child{
  border-bottom-right-radius: 15px;
}
.ant-table-tbody tr th:first-child{
  border-bottom-left-radius: 15px;
}

.ant-table {
  border-radius: 15px !important;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 0 5px !important;
}

.ant-table-column-sorters {
  padding: 10px 5px !important;
}

.graph-footer {
  margin: 0.2rem 0 0;
  font-size: 1.1rem;
  text-align: center;
  color: #525252;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coin-info-sec {
  border-radius: 1rem;
  margin: 0.5rem 0.5rem 3rem;
  padding: 3rem 2rem;
  background-color: #268fc4;
  color: white;
  box-shadow: 0 0 10px 0 rgb(162 177 192 / 20%);
}

.coin-info-title-con {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.coin-info-title-icon img {
  width: 60px;
}

.coin-info-title {
  font-size: 2.2rem;
  margin-left: 20px;
}

.coin-info-btns-con {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.coin-info-up-btn {
  border: solid 1px white;
  font-size: 0.8rem;
  padding: 0.3rem 1.5rem;
  border-radius: 2rem;
  margin: 0 0.5rem;
  transition: transform 0.3s ease 0s, box-shadow 0.3s ease 0s;
}

.coin-info-up-btn:hover {
  cursor: pointer;
  box-shadow: rgb(125 132 153 / 4%) 0px 2px 4px 0px, rgb(125 132 153 / 20%) 0px 4px 18px 0px;
  transform: scale(1.03);
}

.coin-info-sec-lower {
  margin: 2rem 0 0;
  font-size: 0.9rem;
  color: white;
}

.sites-sec {
  margin: 0rem 0 2rem;
}

.sites-sec-th {
  padding: 0.5rem !important;
  font-size: 14px;
  color: #464646;
  text-align: center !important;
}

.sites-sec-td {
  padding: 0.5rem !important;
  height: fit-content;
  font-size: 0.9rem;
  background-color: white;
  text-align: center;
}

.sites-sec-th-center {
  padding: 0.5rem !important;
  font-size: 14px;
  color: #4d4d4a;
  text-align: center !important;
}

.sites-sec-td-center {
  padding: 0.5rem !important;
  height: fit-content;
  font-size: 0.9rem;
  background-color: white;
  text-align: center;
}

.sites-sec-title {
  background: transparent;
  color: #1d1d1d;
  padding: 0.5rem 0;
  text-align: center;
  font-size: 20px;
}

.data-board {
  background: white;
  padding: 15px 10px 25px;
  margin: 1rem 0;
  border-radius: 1rem;
  box-shadow: 0 0 10px 0 rgb(162 177 192 / 20%);
}

.data-board-item {
  text-align: center;
  font-size: 0.9rem;
  color: #535353;
}

.data-board-item-figure {
  font-size: 1rem;
  margin-top: 0.4rem;
  color: #1b1b1b;
  font-weight: 500;
}

.unit {
  font-size: 1rem;
  font-weight: 400;
  color: #565656;
}

.etc-btns-sec .ant-menu-horizontal {
  text-align: center !important;
  background-color: transparent;
}

.etc-btns-sec .ant-menu-horizontal > .ant-menu-item {
  font-size: 16px !important;
  font-weight: 350 !important;
  color: #464646;
  padding: 0 24px !important;
  margin: 0 10px !important;
}

.coin-table-sec .ant-table-thead > tr > th {
  text-align: center;
  font-size: 14px;
  background: white;
  color: #3c3c3c;
  font-weight: 400;
}

.coin-table-sec .ant-table {
  box-shadow: 0 0 10px 0 rgb(162 177 192 / 20%);
}

.ant-table-tbody > tr > td {
  text-align: center;
}

.coin-table-sec {
  margin: 0 8px;
}

.coin-table-sec .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 10px;
}

.etc-btns-sec .ant-collapse-item p, .etc-btns-sec li {
  margin-right: 2px !important;
  font-size: inherit !important;
  margin-left: 2px !important;
  margin-top: inherit !important;
}

.textarea-title {
  padding: 1rem 0;
  font-size: 16px;
}

.textarea-copy {
  margin: 1rem 0;
}

.main-panel-subtitle {
  padding: 5px 35px 15px;
  color: red;
  font-size: 14px;
  text-align: center;
}

.load-more-btn {
  text-align: center;
  padding: 15px 0 5px;
  font-size: 14px;
  color: #717171;
  cursor: pointer;
}

.load-more-btn:hover {
  color: #5c5c5c;
  cursor: pointer;
}

.ant-table-column-sorters {
  align-items: end !important;
}

.address-info-con {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address-inner-con {
  position: relative;
}

.copy-inner-con {
  position: absolute;
  top: 0;
  right: -25px;
}

.ant-alert-message {
  text-align: center;
}

.a-style {
  color: inherit;
}

.a-style:hover {
  color: inherit;
}

.updown-divider {
  height: 12px;
  width: 100%;
}

.transfer-range-label {
  margin: 0 10px;
}

.lg-right-border {
  border-right: 1px solid rgb(224, 224, 224);
}

.blockTable-subtitle {
  padding: 15px;
  font-size: 16px;
  color: #464646;
}

.fresh-btn {
  position: absolute;
  right: 10px;
  bottom: 0;
  display: flex;
}

.fresh-text {
  font-size: 15px;
  font-weight: 500;
  margin: 0 10px;
}

.graph-customization {
  height: 50px;
  padding: 10px 15px 0px;
  margin: 15px 0;
}

.point-space-selector {
  float: left;
}

.day-selector {
  float: right;
}
.lg-margin-top {
  margin-top: 20px;
}

@media screen and (max-width: 993px) {
  .data-board-item {
    margin: 10px 0;
  }
  .ant-table {
    overflow-x: scroll !important;
  }
  .main-panel-table {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1000px) {
  .data-block {
    height: fit-content;
    padding: 0 0 20px;
  }
  .data-board-row {
    margin: 0 !important;
  }
  .transfer-block {
    height: max-content !important;
    display: block;
    padding: 10px 0 10px !important;
  }
  .lg-right-border {
    border-right: none;
  }
  .lg-margin-top {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .search-container {
    min-width: 0;
    width: 100%;
    margin: 0 auto;
  }
  .container {
    padding: 0 0 2rem;
    width: max-content;
  }
  .searchPage .container {
    padding: 1rem 0;
    width: 96%;
    margin: auto;
  }
  .coin-info-sec {
    margin: 30px 10px;
    padding: 30px 10px;
  }
  .coin-info-title {
    font-size: 20px;
    margin-left: 10px;
  }
  .coin-info-up-btn {
    font-size: 12px;
    padding: 5px 15px;
    margin: 0 10px;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 64% !important;
  }
  .coin-info-intro {
    margin-bottom: 30px;
    font-size: 13px;
  }
  .search-sec {
    margin: 30px auto;
  }
  .search-bar {
    width: 260px !important;
  }

  .ant-collapse-item p, li {
    font-size: 10px;
    margin: 2px;
  }
  .etc-btns-sec .ant-menu-horizontal > .ant-menu-item
   {
    font-weight: 350 !important;
    color: #464646;
    padding: 0 10px !important;
    margin: 0 10px !important;
  }
  .account-info {
    font-size: 10px;
    overflow: hidden;
  }
  .etc-btns-panel {
    height: 40px;
    font-size: 12px;
  }
  .etc-btn {
    line-height: 40px;
  }
  .table-sec .ant-table-thead > tr > th {
    font-size: 12px !important;
  }
  .table-sec .ant-table-tbody > tr > td {
    font-size: 12px;
  }
  .etc-btns-sec .ant-collapse-item p, li {
    margin-right: 2px !important;
    font-size: inherit !important;
    margin-left: 2px !important;
    margin-top: inherit !important;
  }
  .coin-type-box {
    margin: 20px auto 25px;
  }
  .etc-btns-sec .ant-menu-horizontal > .ant-menu-item {
    font-size: 14px !important;
  }
  .block-title {
    font-size: 18px;
    padding: 20px;
  }
  .graph-title {
    font-size: 16px;
  }
  .search-page-search-bar {
    width: 64vw;
    min-width: 0px;
  }
  .main-panel-search {
    padding: 50px 10px 0;
  }
  .sites-sec {
    margin: 0rem 10px 2rem;
  }
  .data-board {
    padding: 1.2rem 1rem 1rem;
  }
  .graph-sec {
    margin: 0 10px 1.5rem
  }
  .point-space-selector {
    margin-bottom: 10px;
    float: none;
  }
  .day-selector {
    float: none;
  }
  .graph-customization {
    height: fit-content;
  }
}

.night .search-page {
  background: #141414;
}
.night .coin-info-sec {
  background: #2c2c2c;
  color: #FFFFFFD9;
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 6%);
}
.night .coin-info-sec-lower {
  color: #FFFFFFA6;
}
.night .coin-info-up-btn {
  color: #FFFFFFD9;
  border: solid 1px #FFFFFFD9;
}
.night .search-page-search-bar input {
  background: #2c2c2c;
  border: solid 1px #454545 !important;
}
.night .sites-sec-title {
  color: #FFFFFFA6;
}
.night .main-panel-table {
  background: #2c2c2c !important;
}
.night .panel-table-thead {
  background-color: #2c2c2c !important;
}
.night .sites-sec-td-center {
  background-color: #2c2c2c !important;
  color: #FFFFFFD9 !important;
}
.night .sites-sec-th-center {
  color: #FFFFFFA6;
}
.night .data-board {
  background: #2c2c2c !important;
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 6%);
}
.night .transfer-block {
  background: #2c2c2c !important;
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 6%);
}
.night .transfer-switch {
  color: #FFFFFFA6 !important;
}
.night .data-board-item {
  color: #FFFFFFA6;
}
.night .data-board-item-figure {
  color: #FFFFFFD9;
}
.night .unit {
  color: #f9f8f8cf
}
.night .divider-line {
  background-color: #454545 !important;
}
.night .etc-btns-sec .ant-menu-horizontal > .ant-menu-item {
  color: #FFFFFFA6;
}
.night .graph-panel {
  background: #2c2c2c;
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 6%);
}
.night .graph-title {
  color: #FFFFFFA6;
}
.night .search-page-search-bar input:focus {
  background-color: #2c2c2c !important;
  color: #FFFFFFA6 !important;
}
.night .coin-type-box-title {
  color: #FFFFFFD9 !important;
}
.night .account-info {
  background-color: #2c2c2c;
  color: #FFFFFFA6;
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 6%);
}
.night .data-block {
  background-color: #2c2c2c;
  color: #FFFFFFA6;
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 6%);
}
.night .block-title {
  color: #FFFFFFA6;
}
.night .lower-title {
  color: #FFFFFFA6;
}
.night .lower-figure {
  color: #FFFFFFD9;
}
.night .table-sec .ant-table-thead > tr > th {
  color: #FFFFFFA6 !important;
  background-color: #2c2c2c !important;
  border-bottom: solid 1px #454545 !important;
}
.night .table-sec .ant-table-tbody > tr {
  color: #FFFFFFD9;
  background-color: #2c2c2c;
}
.night .ant-table-tbody > tr > td {
  border-bottom: solid 1px #454545 !important;
}
.night .table-sec .ant-table-tbody > tr:hover {
  background-color: #000000e0;
}
.night .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #000000e0;
}
.night .ant-empty-description {
  color: #FFFFFFA6;
}
.night .sites-sec-td {
  background: #2c2c2c;
}
.night .sites-sec-th {
  color: #FFFFFFA6;
}
.night .coin-table-sec .ant-table-thead > tr > th {
  background: #2c2c2c !important;
  color: #FFFFFFA6 !important;
  border-bottom: solid 1px #454545 !important;
}
.night .coin-table-sec .ant-table-tbody > tr {
  background: #2c2c2c !important;
  color: #FFFFFFD9 !important;
}
.night .coin-table-sec .ant-table {
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 6%);
}
.night .ant-pagination-disabled .ant-pagination-item-link,
.night .ant-pagination-disabled:hover .ant-pagination-item-link, 
.night .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: #FFFFFFA6;
  border-color: #2c2c2c !important;
  background: #2c2c2c !important;
}
.night .ant-pagination-prev .ant-pagination-item-link, 
.night .ant-pagination-next .ant-pagination-item-link {
  color: #FFFFFFA6;
  border-color: #2c2c2c !important;
  background: #2c2c2c !important;
}
.night .ant-pagination-item {
  color: #FFFFFFA6 !important;
  border-color: #2c2c2c !important;
  background: #2c2c2c !important;
}
.night .ant-pagination-item a {
  color: #FFFFFFA6;
}
.night .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #FFFFFFA6;
}
.night .ant-pagination-item-active a {
  color: #1890ff !important;
}
.night .ant-pagination-item-active {
  border-color: #1890ff !important;
}
.night .transfer-range {
  color: #FFFFFFD9 !important;
}
.night .transfer-range-span {
  color: inherit !important;
}
.night .ant-modal-header {
  background: #2c2c2c;
  border-bottom: solid 1px #454545;
}
.night .ant-modal-title {
  color: #FFFFFFA6;
}
.night .ant-modal-body {
  background: #2c2c2c;
  
}
.night .textarea-title {
  color: #FFFFFFA6;
}
.night textarea.ant-input {
  background: #2c2c2c;
  color: #FFFFFFD9;
  border-color: #FFFFFFA6;
}
.night .ant-modal-footer {
  border-top: solid 1px #454545;
  background: #2c2c2c;
}
.night .ant-modal-close-x {
  color: #FFFFFFA6;
}
.night .ant-btn {
  background: #2c2c2c;
  color: #FFFFFFA6;
}
.night .ant-drawer .ant-drawer-content {
  background: #2c2c2c;
}
.night .ant-drawer-header {
  background: #2c2c2c;
  border-bottom: solid 1px #454545;
}
.night .ant-drawer-title {
  color: #FFFFFFA6;
}
.night td.ant-table-column-sort {
  background: #232323e0;
}

.server-list-alert {
  background-color: #fff7ce;
}

.night .server-list-alert {
  background-color: #5d542b;
  border: none;
}

.night .server-list-alert .ant-alert-message {
  color: #FFFFFFA6;
}

.night .blockTable-subtitle {
  color: #FFFFFFA6;
}

.night .fresh-text {
  color: #FFFFFFD9;
}

.night .ant-switch {
  background: #FFFFFFD9;
}

.night .ant-switch-checked {
  background-color: #1890ff;
}

.night .ant-radio-button-wrapper {
  background: #2c2c2c;
  border-color: #2c2c2c;
  color: #FFFFFFD9;
}

.night .ant-radio-button-wrapper:first-child {
  border-left: solid 1px transparent;
}

.night .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #2c2c2c;
  border-color: #40a9ff !important;
}