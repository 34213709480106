.modal-input-title {
  font-size: 15px;
  color: #353535;
  padding: 7px 0;
}

.modal-input-class {
  height: 40px !important;
  font-size: 16px !important;
  border-radius: 6px !important;
}