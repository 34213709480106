:root {
  --background-color: #f5f5f5;
  --btn-color-1: #f7f7f7;
  --home-btn-hover-color: #fff;
  --search-input-color: #4a4949;
  --search-border-color: #2281b1;
  --search-btn-color: #2281b1;
  --search-btn-font-color: white;
  --menu-btn-color: #464646;
  --table-panel-thead-color: #268fc4;
  --table-panel-thead-font: white;
  --data-row-bg: white;
  --data-row-font: #545454;
  --data-row-border-top: transparent;
  --data-row-border-bottom: #eee;
  --data-row-hover: #f8fcfc;
  --data-row-card: #dff4ff;
  --data-row-card-hover: #e6f7ff;
  --data-row-cpu: #e2ffde;
  --data-row-cpu-hover: #e5fae1;
  --action-color: #4a90e2;
  --addr-color: #434343;
  --addr-border-top: #e6e6e6;
  --addr-border-bottom: #e6e6e6;
  --home-footer: #1b668c;
  --figure: #444443;
  --common-header: #268fc4;
  --nav-con: white;
  --main-panel-table-shadow: 0 0 10px 0 rgb(162 177 192 / 20%);
}

.homePage {
  background-color: var(--background-color);
}

.homePage-hero-sec {
  background-color: var(--color8);
  height: 46vh;
}

.logo-sec {
  position: absolute;
  top: 10vh;
  text-align: center;
  width: 100%;
  color: white;
}

.logo-bar {
  font-size: 5rem;
  letter-spacing: 0.5rem;
  font-weight: 500;
}

.logo-subtitle {
  font-size: 1.1rem;
}

.btns-sec {
  position: absolute;
  top: 36vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--btn-color-1);
}

.home-btn {
  border: solid 2.5px var(--btn-color-1);
  margin: 0px 2rem 0;
  padding: 0.5rem 1.4rem;
  font-size: 1.4rem;
  border-radius: 1.5rem;
  font-weight: 500;
  transition: transform 0.3s ease 0s, box-shadow 0.3s ease 0s;
}

.home-btn:hover {
  box-shadow: rgb(125 132 153 / 4%) 0px 2px 4px 0px, rgb(125 132 153 / 20%) 0px 4px 18px 0px;
  transform: scale(1.1);
  color: var(--home-btn-hover-color);
  cursor: default;
}

.homePage-main-panel {
  padding: 0rem 0 4rem;
  min-height: 85vh;
}

.main-panel-search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar {
  width: 500px !important;
  height: 2.6rem;
  font-size: 1rem;
}

.search-bar input {
  width: 100% !important;
  height: 2.6rem !important;
  padding: 0.1rem 0.5rem !important;
  color: var(--search-input-color) !important;
  border: solid 2px var(--search-border-color) !important;
  border-radius: 0.5rem 0 0 0.5rem !important;
}

.autocomplete {
  width: 100% !important;
  height: 100% !important;
}

.search-bar input:focus {
  border: solid 3px var(--search-border-color);
  outline: none;
}

.dec-sec-left {
  position: absolute;
  top: 23vh;
  left: 1vw;
}

.dec-sec-right {
  position: absolute;
  top: 23vh;
  right: 1vw;
}

.search-btn {
  font-size: 1.2rem;
  color: var(--search-btn-font-color);
  text-align: center;
  line-height: 2.6rem;
  width: 6rem;
  height: 2.6rem;
  background-color: var(--search-btn-color);
  border-radius: 0 0.5rem 0.5rem 0;
  transition: transform 0.3s ease 0s, box-shadow 0.3s ease 0s;
}

.search-btn:hover {
  cursor: default;
  box-shadow: rgb(125 132 153 / 4%) 0px 2px 4px 0px, rgb(125 132 153 / 20%) 0px 4px 18px 0px;
  transform: scale(1.03);
}

.table-title {
  text-align: center;
  margin: 2rem 0 2.5rem;
  background-color: transparent;
}

.table-title .ant-menu-horizontal {
  text-align: center !important;
  background-color: transparent;
}

.table-title .ant-menu-horizontal > .ant-menu-item {
  font-size: 24px !important;
  font-weight: 500 !important;
  color: var(--menu-btn-color);
  padding: 0 24px !important;
  margin: 0 10px !important;
}

.main-panel-table {
  margin: 1rem 0 1rem;
  box-shadow: var(--main-panel-table-shadow);
}

.homePage .panel-table-thead {
  background-color: var(--table-panel-thead-color);
  font-size: 14px;
  text-align: center;
  color: var(--table-panel-thead-font);
}

.homePage .panel-table-thead th {
  padding: 1rem;
  font-weight: 400;
}

.container {
  max-width: 1270px;
  min-width: 1040px;
  width: 76vw;
  margin-left: auto;
  margin-right: auto;
}

.data-row {
  height: 3.5rem !important;
  font-size: 1rem;
  text-align: center;
  color: var(--data-row-font);
  border-top: solid 1px var(--data-row-border-top);
  border-bottom: solid 1px var(--data-row-border-bottom);
  background: var(--data-row-bg);
}

.data-row:hover {
  background-color: var(--data-row-hover);
  cursor: pointer;
}

.data-row-card {
  height: 3.5rem !important;
  font-size: 1rem;
  text-align: center;
  color: var(--data-row-font);
  border-bottom: solid 1px var(--data-row-border-bottom);
  background-color: var(--data-row-card);
}

.data-row-card:hover {
  background-color: var(--data-row-card-hover);
  cursor: pointer;
}

.data-row-cpu {
  height: 3.5rem !important;
  font-size: 1rem;
  text-align: center;
  color: var(--data-row-font);
  border-bottom: solid 1px var(--data-row-border-bottom);
  background-color: var(--data-row-cpu);
}

.data-row-cpu:hover {
  background-color: var(--data-row-cpu-hover);
  cursor: pointer;
}

.action {
  font-size: 1rem;
  line-height: 1.4rem;
  color: var(--action-color);
}

.addr {
  border-top: solid 1px var(--addr-border-top);
  border-bottom: solid 1px var(--addr-border-bottom);
  font-size: 0.9rem;
  color: var(--addr-color);
}

.home-footer {
  background-color: var(--home-footer);
}

.common-container {
  max-width: 1270px;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.change-left {
  height: 50vh;
  width: 10vw;
  position: absolute;
  z-index: 5;
  left: 5vw;
  align-items: center;
  display: flex;
  font-size: 3rem;
  justify-content: center;
  background-color: #0000001a;
  color: #0000001a;
  transition: background-color 0.3s ease;
}

.change-left:hover {
  cursor: pointer;
  background-color: #00000070;
  color: #00000070;
}

.change-right {
  height: 50vh;
  width: 10vw;
  position: absolute;
  z-index: 5;
  right: 5vw;
  align-items: center;
  display: flex;
  font-size: 3rem;
  justify-content: center;
  background-color: #0000001a;
  color: #0000001a;
  transition: background-color 0.3s ease;
}

.change-right:hover {
  cursor: pointer;
  background-color: #00000070;
  color: #00000070;
}

.home-footer {
  padding: 4rem 10vw 2rem;
  font-size: 1rem;
  color: #f3f1f1;
  text-align: center;
}

.home-footer-col h3 {
  color: white;
  margin-bottom: 1rem;
}

.home-footer-col a {
  color: #f3f1f1;
}

.home-footer-col a:hover {
  color: white;
  cursor: pointer;
}

.icon-con {
  height: 2rem;
  width: 2rem;
  margin: 0 1rem;
}

.icon-con img {
  width: 100%;
  height: 100%;
}

.sm-show {
  display: none;
}

.sm-show-logo {
  display: none !important;
}

.figure {
  font-size: 1rem;
  font-weight: 500;
  color: var(--figure);
}

.homePage-main-panel .ant-menu-horizontal {
  border-bottom :1px solid #dedede !important; 
}
.homePage-main-panel .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, 
.homePage-main-panel .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, 
.homePage-main-panel .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, 
.homePage-main-panel .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, 
.homePage-main-panel .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, 
.homePage-main-panel .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, 
.homePage-main-panel .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, 
.homePage-main-panel .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #2282b2 !important;
  border-bottom: 2px solid #2282b2 !important;
}

@media screen and (max-width: 940px) {
  .container {
    min-width: 0;
  }
  .sm-hide {
    display: none !important;
  }
  .container {
    width: 96vw;
  }
  .first-col {
    height: fit-content !important;
    padding: 13px 0 10px !important;
    display: block !important;
  }
  .icon-con {
    margin: 0 auto;
    height: 30px;
    width: 30px;
  }
  .logo-con {
    margin: 0 20px;
  }
  .menu-con {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    width: 40% !important;
  }
  .sm-show {
    display: block;
  }
  .sm-col {
    width: 50% !important;
    margin: 20px 0 !important;
  }
  .homePage-main-panel {
    padding: 0rem 0 0;
  }
  .table-title .ant-menu-horizontal > .ant-menu-item {
    font-size: 16px !important;
  }
  .sm-show-logo {
    display: block !important;
  }
}

.night .common-header {
  background-color: #2c2c2c !important;
}

.night .nav-con {
  color: #FFFFFFD9 !important;
}
.night .homePage-main-panel {
  background-color: #1f1f1f;
}
.night .data-row {
  color: #FFFFFFD9 !important;
  background: #2c2c2c !important;
  border-bottom: solid 1px #454545 !important;
  border-top: solid 1px #454545 !important;
}
.night .addr {
  color: #FFFFFFA6 !important;
  border-top: solid 1px #454545 !important;
  border-bottom: solid 1px #454545 !important;
}
.night .figure {
  color: #FFFFFFD9 !important;
}
.night .search-bar input {
  background: #2c2c2c;
  border: solid 1px #454545 !important;
  color: #ffffffd9 !important;
}
.night .search-btn {
  color: #fffffff2 !important;
  background-color: #006cc1 !important;
}
.night .homePage .panel-table-thead {
  background-color: #2c2c2c !important;
}
.night .main-panel-table {
  box-shadow: 0 16px 32px 0 hsl(0deg 0% 38% / 10%) !important
}
.night .homePage .panel-table-thead {
  font-size: 14px;
  color: #FFFFFFA6;
}
.night .data-row-cpu {
  color: #FFFFFFD9 !important;
  background: #2c2c2c !important;
  border-bottom: solid 1px #454545 !important;
}
.night .data-row-card {
  color: #FFFFFFD9 !important;
  background: #2c2c2c !important;
  border-bottom: solid 1px #454545 !important;
}
.night .first-row {
  border-top: solid 1px #454545 !important;
}
.night .table-title .ant-menu-horizontal > .ant-menu-item {
  color: #bbbbbb !important;
}
.night .ant-menu-horizontal {
  border-bottom: solid 1px #454545 !important;
}
.night .data-row:hover {
  background-color: #ffffff0a !important;
}
.night .data-row-cpu:hover {
  background-color: #ffffff0a !important;
}
.night .data-row-card:hover {
  background-color: #ffffff0a !important;
}
.night .common-footer {
  background: #2c2c2c !important;
}
.night .home-footer-col h3 {
  color: #FFFFFFD9 !important;
}
.night .home-footer-col a {
  color: #FFFFFFA6 !important;
}
.night .icon-con {
  opacity: 0.75;
}
.night .lang-con {
  color: #FFFFFFD9;
}