.spin-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.spin-text {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.night .spin-page {
  background: #2c2c2c;
  color: #FFFFFFD9;
}