.setting-panel-container {
  font-size: 20px;
  font-weight: 500;
  padding: 20px 0 40px;
  max-width: 860px;
  margin: auto;
}

.panel-container {
  font-size: 20px;
  font-weight: 500;
  padding: 20px 0 40px;
  max-width: 700px;
  margin: auto;
}

.panel-title {
  font-size: 20px;
  font-weight: 500;
  padding: 20px 0 40px;
}

.panel-block {
  text-align: left;
  padding-bottom: 30px;
}

.panel-block-title {
  font-size: 16px;
  color: #252525;
  padding: 5px 0 15px;
  font-weight: 400;
}

.panel-block-row {
  display: flex;
  font-size: 16px;
  padding: 20px 0 20px;
  border-top: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
  margin-bottom: -1px;
  font-weight: 400;
}

.contact-type {
  width: 20%;
  text-align: center;
  color: #5d5d5d;
}

.contact-content {
  width: 70%;
  font-weight: 500;
  color: black;
}

.contact-btn {
  width: 10%;
  color: #2196f3;
  transition: transform 0.3s ease;
}

.contact-btn:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.add-follow {
  padding: 40px 20px 20px;
}

.add-follow-upper {
  text-align: left;
  font-weight: 400;
  padding: 0 0 25px;
}

.add-follow-title {
  font-size: 18px;
  padding: 0px 0 6px;
}

.add-follow-subtitle {
  font-size: 14px;
  color: #4a4a4a;
}

.add-follow-lower {
  text-align: left;
}

.add-follow-input-box {
  padding: 5px 0;
}

.add-follow-input {
  width: 70% !important;
  height: 40px;
  font-size: 16px !important;
}

.add-follow-btn {
  margin: 15px 0 10px;
  padding: 0 30px !important;
  height: 38px !important;
  border-radius: 6px !important;
  font-size: 16px !important;
}

.follow-list-con {
  padding: 40px 20px 40px;
  border-top: solid 2px #ccc;
  border-bottom: solid 2px #ccc;
}

.common-table {
  width: 100%;
  text-align: left;
  table-layout: fixed;
}

.common-table th {
  font-weight: 400;
  font-size: 15px;
  color: #4a4a4a;
  padding: 10px 0;
  border-bottom: solid 1px #ccc;
}

.common-table td {
  font-size: 14px;
  font-weight: 400;
  padding: 8px 0;
  border-bottom: solid 1px #ccc;
}

.in-table-btn {
  color: #2196f3;
  font-size: 14px;
  transition: transform 0.3s ease;
}

.in-table-btn:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.pag-con {
  margin: 30px 0 0;
  text-align: right;
}

.currency-box {
  margin-left: 15px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.follow-icon {
  height: 13px;
  margin-right: 5px;
  margin-bottom: 3px;
  width: 13px;
}

.changePanel-middle {
  width: 65%;
}

.changePanel-right {
  width: 15%;
}

.common-col-1 {
  width: 2%;
}
.common-col-2 {
  width: 7%;
}
.common-col-3 {
  width: 25%;
}
.common-col-4 {
  width: 55%;
}
.common-col-5 {
  width: 9%;
}
.common-col-6 {
  width: 2%;
}

@media screen and (max-width: 940px) {
  .changePanel-middle {
    width: 60%;
  }
  .changePanel-right {
    width: 20%;
  }
  .follow-list-con {
    padding: 20px 10px 20px;
  }
  .common-col-1 {
    width: 0;
  }
  .common-col-2 {
    width: 12%;
  }
  .common-col-3 {
    width: 25%;
  }
  .common-col-4 {
    width: 45%;
  }
  .common-col-5 {
    width: 18%;
  }
  .common-col-6 {
    width: 0;
  }
  .add-follow-input {
    width: 90% !important;
  }
}

.night .night-text {
  color: #FFFFFFD9;
}

.night .modal-input-title {
  color: #FFFFFFD9;
}