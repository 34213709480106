.userCenter {
  background: #f5f5f5;
}

.userCenter-con {
  width: 800px;
  margin: 100px auto;
}

@media screen and (max-width: 940px) {
  .userCenter {
    width: 100%;
  }
  .userCenter-con {
    width: 100%;
    margin: 20px auto;
  }
}

.night .ant-typography {
  color: #FFFFFFD9;
}

.night .ant-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.night .userCenter {
  background: #1f1f1f;
}

.night .userCenter .ant-menu {
  background: #2c2c2c;
  color: #FFFFFFD9;
  border-right: none;
}

.night .userCenter .ant-menu-item {
  background: #2c2c2c;
  color: #FFFFFFD9;
}

.night .userCenter .ant-menu-item-selected {
  background: #ffffff14 !important;
  color: #FFFFFFD9 !important;
}
