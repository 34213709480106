.reg-header {
    width: 100%;
    left: 0px;
    padding: 1.5625rem 0px;
    line-height: 0;
    user-select: none;
    position: absolute;
    z-index: 122;
    pointer-events: none;
  }
  
  .reg-header-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
  }
  
  .brand {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  
  .brand a {
    font-size: 20px;
    text-decoration: none;
    color: black;
    font-weight: 500;
  }
  
  .language {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  
  .reg-page {
    overflow: hidden;
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 100vh;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative
  }
  
  .reg-section {
    overflow: visible;
    position: static;
    padding: 10.625rem 0px 2.5rem !important;
  }
  
  .reg-container {
    padding-left: 25px;
    padding-right: 25px;
    max-width: calc(1270px);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .reg-panel {
    width: 32.5rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .reg-title {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: -0.38px;
    margin-bottom: 3.125rem;
    text-align: center;
  }
  
  .reg-title p {
    margin-bottom: 0px;
  }
  
  .reg-link {
    font-size: 1.3125rem;
    line-height: 1.33;
    pointer-events: all;
    z-index: 120;
    text-align: center;
    margin: -1.875rem auto 2.5rem;
    max-width: 24.375rem;
    color: inherit;
  }
  
  .reg-link p {
    margin-bottom: 10px;
  }
  
  .reg-link span {
    font-size: 18px;
  }
  
  .learn-more {
    color: rgb(41, 41, 41);
    font-weight: 500;
    position: relative;
    cursor: pointer;
    display: inline-block;
    background: transparent;
    border: 0px;
    padding: 0px;
    appearance: none;
    /*border-bottom: solid #1890ff 3px;*/
  }
  
  .learn-more:hover {
    color: black;
  }
  
  .learn-more::after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    bottom: -1px;
    border-bottom: 3px solid #1890ff;
    transition: width 0.3s ease 0s;
  }
  
  .learn-more:hover::after {
    width: 0;
  }
  
  .eth-reg {
    width: 24.375rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  #eth_reg_email, #eth_reg_password, 
  #eth_reg_confirm {
    font-family: inherit;
    font-size: 23px;
    color: rgb(0, 0, 0);
    margin: 7px;
  }
  
  #reg-form-button {
    height: 50px;
    padding: 0.4px 30px;
    font-size: 20px;
    border-radius: 2px;
    margin-top: 10px;
  }
  
  .reg-button {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  
  .reg-footer {
    padding: 2.5rem 1.25rem 5rem;
    position: relative;
    overflow: hidden;
    z-index: 10;
  }
  
  .footer-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .social-media-list {
    list-style: none;
    margin: 0px 0px 1.75rem;
    padding: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    direction: ltr;
  }
  
  .social-media-list li{
    text-align: center;
    margin: 0px 0.5625rem;
  }
  
  /*
  .social-media-list li a{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 2.8125rem;
    height: 2.8125rem;
    color: rgb(0, 0, 0);
    border-radius: 100px;
    background-color: #1890ff;
    transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
  }
  */
  
  .contact-us {
    text-align: center;
    font-weight: 500;
    margin-bottom: 0.9375rem;
    font-size: 20px;
  }
  
  .service {
    font-size: 1.0625rem;
    color: rgb(154, 154, 154);
    letter-spacing: 0px;
    text-align: center;
    line-height: 1.5rem;
    font-weight: 500;
    margin-bottom: 0.9375rem;
  }
  
  .service a, .author a {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease 0s;
    outline: none !important;
  }
  
  .author {
    font-size: 1.0625rem;
    color: rgb(154, 154, 154);
    letter-spacing: 0px;
    text-align: center;
    line-height: 1.5rem;
  }
  
  @media screen and (max-width: 767px) {
    .reg-title {
      font-size: 30px;
    }
    #reg-form-button {
      width: 100%;
    }
  }