@import '~antd/dist/antd.css';

.spin-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.spin-text {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
}
.fail-icon {
  font-size: 24px;
  color: #cc3e3e;
}

.night .spin-page {
  background: #2c2c2c;
  color: #FFFFFFD9;
}
