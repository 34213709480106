.userCenter {
  background: #f5f5f5;
}

.userCenter-con {
  width: 1100px;
  margin: 60px auto;
}

.left-panel {
  width: 15%;
  height: 100%;
  background: #4daadc;
  border-right: solid 1px #f1f1f1;
  color: white;
  position: absolute;
  border-radius: 15px 0 0 15px;
}

.right-panel {
  border-radius: 0 15px 15px 0;
  text-align: center;
  background: white;
  text-align: center;
  padding: 0 50px;
  position: relative;
  min-height: 80vh;
  left: 15%;
  width: 85%;
  padding: 0 24px;
}

.right-panel {
  scrollbar-width: none;
}

.right-panel::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.both-panel-con {
  display: flex;
  width: 1100px;
  margin: 0 auto;
  min-height: 80vh;
  align-items: center;
  box-shadow: 0 0 10px 0 rgb(162 177 192 / 20%);
  border-radius: 10px;
  position: relative;
}

.left-panel-menu {
  position: sticky;
  top: -70px;
  padding: 80px 0 0;
}

.left-menu-item-click {
  height: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  background: #4892b9;
  color: white;
  margin: 10px 0;
  cursor: pointer;
}

.left-menu-item {
  height: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  margin: 10px 0;
  cursor: pointer;
}

.upper-cover {
  height: 26px;
  position: sticky;
  top: -2px;
  left: 0;
  border-radius: 10px;
  background: white;
  z-index: 99;
  width: 100%;
}

.lower-cover {
  height: 26px;
  position: sticky;
  bottom: -2px;
  left: 0;
  border-radius: 10px;
  background: white;
  z-index: 99;
  width: 100%;
}

@media screen and (max-width: 940px) {
  .userCenter {
    width: 100%;
  }
  .userCenter-con {
    width: 100%;
    margin: 20px auto;
  }
  .both-panel-con {
    margin: 5px;
    width: calc(100% - 10px);
  }
  .left-panel {
    display: none;
  }
  .right-panel {
    width: 100%;
    left: 0;
    margin: 5px;
    border-radius: 10px;
    padding: 0 10px;
  }
  .sm-menu {
    width: 60%;
    padding: 10px 0 5px;
    margin: 10px auto 20px;
    background: white;
  }
  .login-button {
    text-align: center;
  }
}

.night .userCenter {
  background: #1f1f1f;
}

.night .both-panel-con {
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 6%);
}

.night .left-panel {
  background: #2c2c2c;
  color: #FFFFFFD9;
  border-right: solid 1px #FFFFFFA6;
}

.night .right-panel {
  background: #2c2c2c;
}

.night .left-menu-item-click {
  background: #ffffff14 !important;
  color: #FFFFFFD9;
}

.night .panel-title {
  color: #FFFFFFD9;
}

.night .contact-type {
  color: #FFFFFFA6;
}

.night .contact-content {
  color: #FFFFFFD9;
}

.night .changePanel-middle {
  color: #FFFFFFD9;
}

.night .common-table th {
  color: #FFFFFFA6;
}

.night .add-follow-title {
  color: #FFFFFFD9;
}

.night .common-table td {
  color: #FFFFFFD9;
}

.night .add-follow-subtitle {
  color: #FFFFFFA6;
}

.night .ant-input-clear-icon {
  color: #FFFFFFD9;
}

.night .reg-link {
  color: #FFFFFFA6;
}

.night .learn-more {
  color: #FFFFFFA6;
}

.night #to-reg a {
  color: #FFFFFFA6;
}

.night .reg-page {
  background: #1f1f1f;
}

.night .reg-title {
  color: #FFFFFFD9;
}

.night .sm-menu {
  background: #2c2c2c;
}

.night .userCenter .ant-menu {
  background: #2c2c2c;
  color: #FFFFFFD9;
  border-right: none;
}

.night .userCenter .ant-menu-item {
  background: #2c2c2c;
  color: #FFFFFFD9;
}

.night .userCenter .ant-menu-item-selected {
  background: #ffffff14 !important;
  color: #FFFFFFD9 !important;
}

.night .panel-block-title {
  color: #FFFFFFD9;
}