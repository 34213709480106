:root {
  --color5: #40a9ff;
  --color6: #1890ff;
  --color7: #096dd9;
  --color8: #0050b3;
}

.common-header {
  height: 64px;
  background-color: var(--common-header);
}

.sides {
  height: 64px;
  padding: 0 20px;
  width: 100%;
}

.common-logo-con {
  height: 100%;
  display: flex;
  float: left;
  align-items: center;
}

.side-btns-con {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
}

.menu-con {
  float: right;
}

.center-nav {
  margin-top: -64px;
  height: 64px;
  width: 100%;
  text-align: center;
  transition: transform 0.3s ease;
}

.nav-btn-a:hover {
  color: white;
  border-bottom: solid 2px white;
}

.nav-btn-a-text:hover {
  transform: scale(1.05);
  color: white;
}

.center-btns {
  width: fit-content;
  margin: 0 auto;
}

.nav-btn-a {
  color: white;
  font-size: 18px;
  line-height: 64px;
  padding: 20px;
}

.nav-btn {
  height: 100%;
  line-height: 64px;
  color: white;
  font-size: 18px;
  padding: 0 10px;
  transition: transform 0.3s ease;
}

.nav-btn:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.nav-btn-text {
  font-size: 18px;
}

.nine-show {
  display: none;
}

.menu-con {
  height: 64px;
}

.user-a {
  color: white;
  font-size: 18px;
  padding: 0 2px;
  transition: transform 0.3s ease;
}

.user-a:hover {
  color: white;
  transform: scale(1.05);
  padding: 0 2px;
}

@media screen and (max-width: 1200px) {
  .center-nav {
    width: 70%;
  }
}

@media screen and (max-width: 940px) {
  .nine-hide {
    display: none;
  }
  .nine-show {
    display: block;
  }
}

.content-block {
  cursor: pointer;
  text-align: center;
  color: #676161;
  padding: 1rem 1.5rem;
}

.content-block:hover {
  color: black;
}

.content-block-img {
  width: 3rem;
  height: 3rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.1rem;
  
}

.content-block a {
  text-decoration: none;
  color: inherit;
}

.content-block-img img {
  width: 100%;
  height: 100%;
}

.content-block span {
  font-size: 1.2rem;
}
