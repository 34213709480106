.common-footer {
  background-color: #1b668c;
  padding: 4rem 10vw 2rem;
  font-size: 1rem;
  color: #f3f1f1;
  text-align: center;
}

.common-footer-col h3 {
  color: white;
  margin-bottom: 1rem;
}

.common-footer-col a {
  color: #f3f1f1;
}

.common-footer-col a:hover {
  color: white;
  cursor: pointer;
}

.contact-icon-con {
  width: 240px;
  margin: 30px auto;
}

@media (prefers-color-scheme: dark) { 
  .common-footer-col h3 {
    color: #FFFFFFD9 !important;
  }
  .common-footer-col a {
    color: #FFFFFFA6 !important;
  }
  .common-footer {
    color: #FFFFFFA6;
  }
} 
